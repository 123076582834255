import {Link} from "react-router-dom";
import {FAQRoute} from "../../constants/frontRoutes";

function Footer () {
    return (
        <>
            <footer id={"footer"}>©  divineion 2023 - <Link to={FAQRoute}>Au sujet de l'indice PRAL</Link></footer>
        </>
    )
}

export default Footer;