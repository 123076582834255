const FAQ = () => {
    <p></p>
    const questions = [
        {
            "question": "C’est quoi, l’indice PRAL?",
            "answer": "L'indice PRAL, c'est un peu comme une boussole pour savoir si un aliment va avoir un effet acidifiant ou alcalinisant sur notre corps. Mais plutôt que de se baser sur des théories compliquées ou de devoir tester son urine après chaque repas (pas très pratique, avouons-le !), les scientifiques ont trouvé un moyen simple de mesurer cet impact avec un chiffre.\n" +
                "PRAL, ça signifie \"Potential Renal Acid Load\", ou en français \"Charge Rénale Acide Potentielle\". En gros, il nous aide à comprendre si un aliment va rendre notre corps plus acide ou plus alcalin. Ce calcul prend en compte les minéraux présents dans l'aliment, mais aussi la quantité de protéines. Par exemple, certains aliments riches en protéines ou en minéraux acidifiants (comme la viande ou les produits laitiers) auront un PRAL plus élevé, donc plus acidifiant. Tandis que des aliments comme les fruits et légumes, riches en minéraux alcalinisants, auront un PRAL plus bas, donc plutôt alcalinisants."
        },
        {
            "question": "Pourquoi c'est important ?",
            "answer": "Maintenir un bon équilibre acido-basique est crucial pour notre santé. Par exemple, à mesure qu’on vieillit, ou en cas de certaines pathologies rénales, il peut être conseillé de privilégier un régime alcalinisant. Mais parfois, un régime légèrement acidifiant peut être nécessaire, notamment en cas de certains troubles métaboliques.\n" +
                "En résumé, l’indice PRAL est un outil pratique pour mieux comprendre l'impact des aliments sur notre corps et nous aider à faire des choix alimentaires plus équilibrés. "
        },
        {
            "question": "Comment savoir si un aliment est acidifiant ou alcalinisant?",
            "answer": "Voici comment ça fonctionne :\n" +
                "    • PRAL positif (>0) : l'aliment aura tendance à acidifier le corps.\n" +
                "    • PRAL négatif (<0) : l'aliment aura un effet alcalinisant.\n" +
                "    • PRAL égal à 0 : cet aliment est neutre, il n'aura pas d'effet significatif sur l’équilibre acide-base du corps."
        },
        {
            "question": "Comment l'indice PRAL est calculé ?",
            "answer": "Le calcul de l'indice PRAL prend en compte plusieurs facteurs, comme les niveaux de minéraux acidifiants (phosphore, soufre, etc.) et alcalinisants (calcium, magnésium, potassium, etc.) ainsi que la teneur en protéines de l'aliment. Voici une idée générale de la formule utilisée, même si les valeurs exactes sont plus détaillées dans la recherche scientifique :\n" +
                "\n" +
                "Minéraux acidifiants : Ceux-ci comprennent le phosphore, le soufre, le chlorure, etc., qui augmentent l'acidité dans le corps.\n" +
                "Minéraux alcalinisants : Calcium, potassium et magnésium ont un effet opposé, en alcalinisant le corps.\n" +
                "Protéines : Les protéines, notamment les protéines animales, sont également acidifiantes.\n" +
                "La formule (très simplifiée) ressemble à ça : 0,49 X protéine (g/j) + 0,037 X phosphore (mg/j) – 0,021 X potassium (mg/j) – 0,026 X magnesium (mg/j) – 0,013 X calcium (mg/j) \n" +
                "\n" +
                "Cela donne un résultat en milliéquivalents (mEq), qui indique si l’aliment acidifie ou alcalinise le corps."
        },
        {
            "question": "Il faut que je calcule tout ça ?",
            "answer": "Non, vous n’avez pas besoin de calculer tout ça !\n" +
                "Vous pouvez simplement utiliser PRAL Calc comme outil de recherche d’indices PRAL d’aliments, et même comme outil de journalisation alimentaire ! \n" +
                "En vous inscrivant en quelques clics, vous  accédez à un calendrier qui vous permet d’enregistrer vos prises alimentaires et l’indice PRAL associé à chacune d’elles. \n" +
                "Si vous ne souhaitez pas enregistrer vos repas, vous pouvez simplement utiliser l’outil de recherche qui utilise pour ses calculs la table Ciqual, base de données officielles de l’Agence nationale de sécurité sanitaire. "
        }
    ]

    return (
        <>
            {questions.map((question, index) => {
                return (
                    <>
                        <h3> {question.question}</h3>
                        <p>{question.answer}</p>
                    </>
                )
            })}
        </>
    )
}

export default FAQ;