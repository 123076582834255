import React, {useEffect, useState} from "react";
import Loader from "../Loader";
import {useOutletContext} from "react-router-dom";

const RecipesList = () => {
    const [recipesList, setRecipesList] = useState([]);

    const {loading, fetchRecipesList} = useOutletContext();

    const fetchRecipe = (id) => {
        return id
    }

    useEffect(() => {
        fetchRecipesList()
            .then(data => setRecipesList(data))
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  return (
      <>
          { recipesList &&
          <section>
          <h2>Toutes les recettes</h2>
              {loading &&
              <Loader></Loader>}

              { !loading &&
                  recipesList.map((recipe) =>
                      <ul key={recipe.id}>
                          <li>
                              <div onClick={() => fetchRecipe(recipe.id)}>Recette : {recipe.title}</div>
                              <p>Classée dans : {recipe.category} - {recipe.subcategory}</p>
                          </li>
                      </ul>
                  )
              }
        </section>}
      </>
  )
}

export default RecipesList;
