import {
    EVENT_CREATE_FAIL,
    EVENT_CREATE_REQUEST,
    EVENT_CREATE_SUCCESS,
    EVENT_DELETE_FAIL,
    EVENT_DELETE_REQUEST,
    EVENT_DELETE_SUCCESS,
    EVENT_FETCH_FAIL,
    EVENT_FETCH_REQUEST, EVENT_RESET_REQUEST, EVENT_RESET_SUCCESS, EVENT_RESET_FAIL,
    EVENT_FETCH_SUCCESS,
    EVENT_UPDATE_FAIL,
    EVENT_UPDATE_REQUEST,
    EVENT_UPDATE_SUCCESS

} from "../actions/eventActions";
import {USER_LOGOUT} from "../actions/userActions";

const initialState = {
    eventInfos: {
        fetchedEvent: {}
    },
    loading: null
}
export const eventReducer = (state = initialState, action)=> {
    switch (action.type) {
        case EVENT_FETCH_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }

        case EVENT_FETCH_SUCCESS: {
            return {
                ...state,
                eventInfos: {
                    ...state.eventInfos,
                    fetchedEvent: action.payload
                },
                loading: false
            }
        }

        case EVENT_FETCH_FAIL: {
            return {
                ...state,
                loading: false
            }
        }

        case EVENT_RESET_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }

        case EVENT_RESET_SUCCESS: {
            return {
                ...state,
                eventInfos: {
                    ...state.eventInfos,
                    fetchedEvent: {}
                },
                loading: false
            }
        }

        case EVENT_RESET_FAIL: {
            return {
                ...state,
                loading: false
            }
        }

        case EVENT_CREATE_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }

        case EVENT_CREATE_SUCCESS: {
            return {
                ...state,
                loading: true
            }
        }

        case EVENT_CREATE_FAIL: {
            return {
                ...state,
                loading: false
            }
        }

        case EVENT_UPDATE_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }

        case EVENT_UPDATE_SUCCESS: {
            return {
                ...state,
                eventInfos: {
                    ...state.eventInfos,
                    fetchedEvent: {}
                },
                loading: true
            }
        }

        case EVENT_UPDATE_FAIL: {
            return {
                ...state,
                loading: false
            }
        }

        case EVENT_DELETE_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }

        case EVENT_DELETE_SUCCESS: {
            return {
                ...state,
                eventInfos: {
                    ...state.eventInfos,
                    fetchedEvent: {}
                },
                loading: false
            }
        }

        case EVENT_DELETE_FAIL: {
            return {
                ...state,
                loading: false
            }
        }

        case USER_LOGOUT: {
            return {
                ...state,
                eventInfos: {
                ...state.eventInfos,
                    fetchedEvent: {}
                },
                loading: null
            }
        }

        default:
            return state
    }
}