import {
    RECIPES_FETCH_FAIL,
    RECIPES_FETCH_REQUEST,
    RECIPES_FETCH_SUCCESS,
} from "../actions/recipeActions";
import {USER_LOGOUT} from "../actions/userActions";

const initialState = {
    loading: false,
    error: null
}
export const recipesListReducer = (state= initialState, action)=> {
    switch (action.type) {
        case RECIPES_FETCH_REQUEST : {
            return {
                ...state,
                loading: true
            }
        }

        case RECIPES_FETCH_SUCCESS : {
            return {
                ...state,
                loading: false
            }
        }

        case RECIPES_FETCH_FAIL: {
            return {
                ...state,
                loading: false
            }
        }

        case USER_LOGOUT: {
            return {
                ...state,
                loading: false,
                error: null
            }
        }
        default:
            return state
    }
}