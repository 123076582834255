import axios from "axios"
import {
    loginRoute,
    profileRoute,
    userRoute,
    fetchEventsRoute,
    registrationRoute,
    verifyUsernameIsAvailableRoute, apiRecipes,
} from "../constants/apiRoutes"
import {config, headers} from "../constants/axiosConfig"
import {homeRoute} from "../constants/frontRoutes";

export const USER_ACCOUNT_CREATE_REQUEST = 'USER_ACCOUNT_CREATE_REQUEST';
export const USER_ACCOUNT_CREATE_SUCCESS = 'USER_ACCOUNT_CREATE_SUCCESS';
export const USER_ACCOUNT_CREATE_FAIL = 'USER_ACCOUNT_CREATE_FAIL';
export const FETCH_TOKEN_REQUEST = 'FETCH_TOKEN_REQUEST'
export const FETCH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS'
export const FETCH_TOKEN_FAIL = 'FETCH_TOKEN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_INFOS_SUCCESS = 'USER_INFOS_SUCCESS';
export const USER_INFOS_REQUEST = 'USER_INFOS_REQUEST';
export const USER_INFOS_FAIL = 'USER_INFOS_FAIL';
export const USER_EVENTS_FETCH_REQUEST = 'USER_EVENTS_FETCH_REQUEST';
export const USER_EVENTS_FETCH_SUCCESS = 'USER_EVENTS_FETCH_SUCCESS';
export const USER_EVENTS_FETCH_FAIL = 'USER_EVENTS_FETCH_FAIL';
export const USER_RECIPES_FETCH_REQUEST = 'USER_RECIPES_FETCH_REQUEST';
export const USER_RECIPES_FETCH_SUCCESS = 'USER_RECIPES_FETCH_SUCCESS';
export const USER_RECIPES_FETCH_FAIL = 'USER_RECIPES_FETCH_FAIL';
export const USER_ACCOUNT_DELETE_REQUEST = 'USER_ACCOUNT_DELETE_REQUEST';
export const USER_ACCOUNT_DELETE_SUCCESS = 'USER_ACCOUNT_DELETE_SUCCESS';
export const USER_ACCOUNT_DELETE_FAIL = 'USER_ACCOUNT_DELETE_FAIL';
export const GET_DISPLAY_CUSTOMIZATION_REQUEST = "GET_DISPLAY_CUSTOMIZATION_REQUEST";
export const GET_DISPLAY_CUSTOMIZATION_SUCCESS = "GET_DISPLAY_CUSTOMIZATION_SUCCESS";
export const GET_DISPLAY_CUSTOMIZATION_FAIL = "GET_DISPLAY_CUSTOMIZATION_FAIL";
export const UPDATE_DISPLAY_CUSTOMIZATION_REQUEST = "UPDATE_DISPLAY_CUSTOMIZATION_REQUEST";
export const UPDATE_DISPLAY_CUSTOMIZATION_SUCCESS = "UPDATE_DISPLAY_CUSTOMIZATION_SUCCESS";
export const UPDATE_DISPLAY_CUSTOMIZATION_FAIL = "UPDATE_DISPLAY_CUSTOMIZATION_FAIL";
export const USER_LATEST_RECIPES_FETCH_REQUEST = "USER_LATEST_RECIPES_FETCH_REQUEST"
export const USER_LATEST_RECIPES_FETCH_SUCCESS = "USER_LATEST_RECIPES_FETCH_SUCCESS"
export const USER_LATEST_RECIPES_FETCH_FAIL = "USER_LATEST_RECIPES_FETCH_FAIL"


export const verifyUsernameIsAvailable = async (desiredUsername) => {
    const { data } = await axios.get(`${verifyUsernameIsAvailableRoute}${desiredUsername}`, config)

    return data
}

export const createNewUser = (userEmail, username, password, tokenToValidate) => async (dispatch) => {
    try {
        dispatch({
            type: USER_ACCOUNT_CREATE_REQUEST
        })

        const {response} = await axios.post(
            registrationRoute,
            {
                username: username,
                email: userEmail,
                password: password,
                recaptchaToken: tokenToValidate
            },
            config
        )

        dispatch({
            type: USER_ACCOUNT_CREATE_SUCCESS,
        })

        dispatch(getUserAuthToken(userEmail, password))
    } catch (error) {
        dispatch({
            type: USER_ACCOUNT_CREATE_FAIL,
            payload: error.response
        })
    }
}

//fetch JWT
export const getUserAuthToken = (userEmail, userPassword) => async (dispatch) => {
    //check and store JWT
    try {
        dispatch({
            type: FETCH_TOKEN_REQUEST
        })

        const { data } = await axios.post(loginRoute,
            { username: userEmail, password: userPassword },
            {withCredentials: true},
            config)
        localStorage.setItem("token", data.token);
        localStorage.setItem("userEmail", userEmail);

        dispatch({
            type: FETCH_TOKEN_SUCCESS,
            payload: data,
        })

        try {
            dispatch({
                type: USER_INFOS_REQUEST,
            });

            const {data} = await axios.get(`${userRoute}/${userEmail}`, headers());

            dispatch({
                type: USER_INFOS_SUCCESS,
                payload: data
            });

        } catch (error) {
            console.log('erreur lors du fetch : ', error)
            await dispatch({
                type: USER_INFOS_FAIL,
                payload: error.message
            })
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message
        console.log(errorMessage)
        await dispatch({
            type: FETCH_TOKEN_FAIL,
            payload: errorMessage
        })
    }
}

export const userLogout = () => {
    localStorage.removeItem('token')

    return {
        type: USER_LOGOUT
    }
}

export const  getNutrientsDisplayPreference = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_DISPLAY_CUSTOMIZATION_REQUEST,
        });

        const {data} = await axios.get(`${profileRoute}/${id}`, headers());

        dispatch({
            type: GET_DISPLAY_CUSTOMIZATION_SUCCESS,
                payload: data,
        });

    } catch (error) {
        console.log('erreur lors du dispatch : ', error)
        await dispatch({
            type: GET_DISPLAY_CUSTOMIZATION_FAIL,
        })
    }
}

export const  updateNutrientsDisplayPreference = (id, checkedItems) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_DISPLAY_CUSTOMIZATION_REQUEST,
        });
        const {data} = await axios.post(`${profileRoute}/${id}`, checkedItems, headers());

        console.log(data)

        dispatch({
            type: UPDATE_DISPLAY_CUSTOMIZATION_SUCCESS,
                payload: data,
        });

    } catch (error) {
        console.log('erreur lors du dispatch : ', error)
        await dispatch({
            type: UPDATE_DISPLAY_CUSTOMIZATION_FAIL,
        })
    }
}

export const fetchUserEvents = (userId, dateRange) => async (dispatch)  => {
    try {
        dispatch({
            type: USER_EVENTS_FETCH_REQUEST
        })
        const params = {
            startDate: dateRange.start,
            endDate: dateRange.end
        }

        const {data} = await axios.get(`${fetchEventsRoute}/${userId}/events`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                params: params
            }
        )
        dispatch({
            type: USER_EVENTS_FETCH_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error)
        dispatch({
            type: USER_EVENTS_FETCH_FAIL,
            payload: error
        })
    }
}

export const fetchUserRecipes = (userId) => async (dispatch) => {
    try {
        dispatch({
            type: USER_RECIPES_FETCH_REQUEST
        })
        const {data} = await axios.get(`${apiRecipes}/user/${userId}`, headers())

        dispatch({
            type: USER_RECIPES_FETCH_SUCCESS,
            payload: data
        })

        return data
    } catch(error) {
        dispatch({
            type: USER_RECIPES_FETCH_FAIL
        })
    }
}

export const fetchLatestUserRecipes = (userId) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LATEST_RECIPES_FETCH_REQUEST
        })
        const {data} = await axios.get(`${profileRoute}/${userId}/recipes/latest`, headers())

        dispatch({
            type: USER_LATEST_RECIPES_FETCH_SUCCESS,
            payload: data
        })

        return data
    } catch(error) {
        dispatch({
            type: USER_LATEST_RECIPES_FETCH_FAIL
        })
    }
}

export const deleteAccount = (userId, navigate) => async (dispatch) => {
    try {
        dispatch({
            type: USER_ACCOUNT_DELETE_REQUEST
        })

        await axios.delete(`${profileRoute}/${userId}/delete`, headers())

        dispatch({
            type:USER_ACCOUNT_DELETE_SUCCESS
        })

        navigate(homeRoute)
    } catch (error) {
        console.log(error)
        dispatch({
            type: USER_ACCOUNT_DELETE_FAIL,
            payload: error
        })
    }
}