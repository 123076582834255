import {
  FETCH_TOKEN_FAIL,
  FETCH_TOKEN_REQUEST,
  FETCH_TOKEN_SUCCESS,
  GET_DISPLAY_CUSTOMIZATION_FAIL,
  GET_DISPLAY_CUSTOMIZATION_REQUEST,
  GET_DISPLAY_CUSTOMIZATION_SUCCESS,
  UPDATE_DISPLAY_CUSTOMIZATION_FAIL,
  UPDATE_DISPLAY_CUSTOMIZATION_REQUEST,
  UPDATE_DISPLAY_CUSTOMIZATION_SUCCESS,
  USER_ACCOUNT_CREATE_FAIL,
  USER_ACCOUNT_CREATE_REQUEST,
  USER_ACCOUNT_CREATE_SUCCESS,
  USER_ACCOUNT_DELETE_FAIL,
  USER_ACCOUNT_DELETE_REQUEST,
  USER_ACCOUNT_DELETE_SUCCESS,
  USER_EVENTS_FETCH_FAIL,
  USER_EVENTS_FETCH_REQUEST,
  USER_EVENTS_FETCH_SUCCESS,
  USER_INFOS_FAIL,
  USER_INFOS_REQUEST,
  USER_INFOS_SUCCESS,
  USER_LATEST_RECIPES_FETCH_FAIL,
  USER_LATEST_RECIPES_FETCH_REQUEST,
  USER_LATEST_RECIPES_FETCH_SUCCESS,
  USER_LOGOUT,
  USER_RECIPES_FETCH_FAIL,
  USER_RECIPES_FETCH_REQUEST,
  USER_RECIPES_FETCH_SUCCESS
} from "../actions/userActions";

const initialState = {
  usersList: [],
  userInfos: {
    customization: {},
    isLoggedIn: false,
    token: null,
    email: null,
    id: null,
    username: null,
    userRecipesList: [],
    userEvents: [],
  },
  loading: null,
  error: null
}
export const userReducer = (state = initialState, action)=> {
  switch (action.type) {

    case FETCH_TOKEN_REQUEST : {
      return {
          ...state,
        loading: true,
        error: null
        }
    }

    case FETCH_TOKEN_SUCCESS : {
      return {
        ...state,
        userInfos : {
          ...state.userInfos,
          token: action.payload.token,
          isLoggedIn: true,
          email: localStorage.getItem('userEmail'),
        },
        loading: false,
        error: null
      }
    }

    case FETCH_TOKEN_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case USER_ACCOUNT_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }

    case USER_ACCOUNT_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null
      }
    }

    case USER_ACCOUNT_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case USER_INFOS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }

    case USER_INFOS_SUCCESS : {
      return {
        ...state,
        userInfos : {
          ...state.userInfos,
            id: action.payload.userId,
            username: action.payload.username,
            customization: action.payload.customization,
            isLoggedIn: true,
          },
        loading: false,
        error: null
      }
    }

    case USER_INFOS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case USER_EVENTS_FETCH_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }

    case USER_EVENTS_FETCH_SUCCESS: {
      return {
        ...state,
        userInfos: {
          ...state.userInfos,
          userEvents: action.payload
        },
        loading: false,
        error: null
      }
    }

    case USER_EVENTS_FETCH_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case USER_RECIPES_FETCH_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }

    case USER_RECIPES_FETCH_SUCCESS: {
      return {
        ...state,
        userInfos: {
          ...state.userInfos,
          userRecipesList: action.payload,
        },
        loading: false,
        error: null
      }
    }

    case USER_RECIPES_FETCH_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case USER_LATEST_RECIPES_FETCH_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }

    case USER_LATEST_RECIPES_FETCH_SUCCESS: {
      return {
        ...state,
        userInfos: {
          ...state.userInfos,
          userRecipesList: action.payload,
        },
        loading: false,
        error: null
      }
    }

    case USER_LATEST_RECIPES_FETCH_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case UPDATE_DISPLAY_CUSTOMIZATION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }

    case UPDATE_DISPLAY_CUSTOMIZATION_SUCCESS: {
      return {
        ...state,
        userInfos: {
          ...state.userInfos,
          customization: action.payload,
        },
        loading: false,
        error: null
      }
    }

    case UPDATE_DISPLAY_CUSTOMIZATION_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case GET_DISPLAY_CUSTOMIZATION_REQUEST: {
      return {
        ...state,
        userInfos: {
          ...state.userInfos,
        },
        loading: true,
        error: null
      }
    }

    case GET_DISPLAY_CUSTOMIZATION_SUCCESS: {
      return {
        ...state,
        userInfos: {
          ...state.userInfos,
          customization: action.payload,
        },
        loading: false,
        error: null
      }
    }

    case GET_DISPLAY_CUSTOMIZATION_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case USER_LOGOUT : {
      return {
        ...state,
        userInfos: {
          ...state.userInfos,
          customization: {},
          isLoggedIn: false,
          token: null,
          email: null,
          id: null,
          username: null,
          userRecipesList: []
        },
        error: null
      }
    }

    case USER_ACCOUNT_DELETE_REQUEST : {
      return {
        ...state,
        loading: true,
        error: null
      }
    }

    case USER_ACCOUNT_DELETE_SUCCESS : {
      return {
        ...state,
        userInfos: {
          ...state.userInfos,
          isLoggedIn: false,
          token: null,
          email: null,
          id: null,
        },
        loading: false,
        error: null
      }
    }

    case USER_ACCOUNT_DELETE_FAIL : {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    default:
      return state
  }
}