import {
    ALIMENT_INFOS_FAIL,
    ALIMENT_INFOS_REQUEST,
    ALIMENT_INFOS_SUCCESS,
    ALIMENTS_INFOS_FAIL,
    ALIMENTS_INFOS_REQUEST,
    ALIMENTS_INFOS_SUCCESS
} from "../actions/alimActions";
import {USER_LOGOUT} from "../actions/userActions";

const initialState = {
    alimList: [],
    alimentInfos: {},
    loading: false,
    error: null
}
export const alimReducer = (state= initialState, action)=> {
    switch (action.type) {

        case ALIMENTS_INFOS_REQUEST : {
            return {
                ...state,
                loading: true
            }
        }

        case ALIMENTS_INFOS_SUCCESS : {
            return {
                ...state,
                alimList: action.payload,
                loading: false
            }
        }

        case ALIMENTS_INFOS_FAIL: {
            return {
                ...state,
                error: action.payload
            }
        }

        case ALIMENT_INFOS_REQUEST : {
            return {
                ...state,
                loading: true
            }
        }

        case ALIMENT_INFOS_SUCCESS : {
            return {
                ...state,
                alimentInfos:  action.payload,
                loading: false
            }
        }

        case ALIMENT_INFOS_FAIL: {
            return {
                ...state
            }
        }

        case USER_LOGOUT: {
            return {
                ...state,
                alimList: [],
                alimentInfos: {},
                loading: false,
                error: null
            }
        }

        default:
            return state
    }
}