import {configureStore} from "@reduxjs/toolkit";
import {alimReducer} from "../reducers/alimReducer";
import {userReducer} from "../reducers/userReducer";
import {persistStore, persistReducer} from 'redux-persist'
import storage from "redux-persist/lib/storage";
import {combineReducers} from "@reduxjs/toolkit";
import {thunk} from "redux-thunk";
import {recipeReducer} from "../reducers/recipeReducer";
import {snackbarReducer} from "../reducers/snackbarReducer";
import {eventReducer} from "../reducers/eventReducer";
import {recipesListReducer} from "../reducers/recipesListReducer";

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    aliments: alimReducer,
    users: userReducer,
    recipe: recipeReducer,
    recipesList: recipesListReducer,
    snackbar: snackbarReducer,
    events: eventReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore(
    {
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) => [thunk]
    })

export const persistor = persistStore(store);

