import { SET_SNACKBAR } from "../actions/snackbarActions"

export const snackbarReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_SNACKBAR:
            const { isOpen, type, message } = action.snackbar
            return {
                ...state,
                isOpen,
                type,
                message
            }

        default:
            return state
    }
}