import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {
    alimentRoute, alimentsRoute,
    allRecipesRoute,
    diaryRoute,
    homeRoute, notFoundRoute,
    recipesRoute,
    userRecipesRoute,
    profileRoute
} from "../constants/frontRoutes";

const RedirectHandler = () => {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => state?.users?.userInfos?.isLoggedIn)
    const userId = useSelector(state => state.users?.userInfos?.id)

    /**
     * Check if the user is logged in and if the user ID is set
     *
     * If either condition is false, redirect to the home page
     */
    useEffect(() => {
        if (!isLoggedIn || userId === null) {
            navigate(homeRoute)
        }
    }, [isLoggedIn, userId])

    /**
     * Handle browser URL redirection
     *  - retrieve the current path
     *  - redirect to the corresponding route in React Router
     */
    useEffect(() => {
        const currentPath = window.location.pathname;

        const routeMappings = {
            "/": homeRoute,
            "/journal": diaryRoute,
            "/profil": profileRoute,
            "/recettes": recipesRoute,
            "/recettes/mes-recettes": userRecipesRoute,
            "/recettes/toutes-les-recettes": allRecipesRoute,
            "/aliment": alimentRoute,
            "/aliments": alimentsRoute,
            "*": notFoundRoute
        }

        if (routeMappings[currentPath]) {
            navigate(routeMappings[currentPath]);
        }
    }, [navigate]);

    return null;
}

export default RedirectHandler;