import {
    RECIPE_CREATE_FAIL,
    RECIPE_CREATE_REQUEST,
    RECIPE_CREATE_SUCCESS,
    RECIPE_INFOS_FAIL,
    RECIPE_INFOS_REQUEST,
    RECIPE_INFOS_SUCCESS,
    RECIPES_CATEGORIES_FETCH_FAIL,
    RECIPES_CATEGORIES_FETCH_REQUEST,
    RECIPES_CATEGORIES_FETCH_SUCCESS,
    RECIPES_CATEGORY_SUBCATEGORIES_FETCH_FAIL,
    RECIPES_CATEGORY_SUBCATEGORIES_FETCH_REQUEST,
    RECIPES_CATEGORY_SUBCATEGORIES_FETCH_SUCCESS,
    RECIPES_SUBCATEGORIES_FETCH_FAIL,
    RECIPES_SUBCATEGORIES_FETCH_REQUEST,
    RECIPES_SUBCATEGORIES_FETCH_SUCCESS
} from "../actions/recipeActions";
import {USER_LOGOUT} from "../actions/userActions";

const initialState = {
    recipeInfos: {},
    loading: false,
    error: null
}
export const recipeReducer = (state= initialState, action)=> {
// The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        case RECIPES_CATEGORIES_FETCH_REQUEST: {
            return {
                ...state,
            }
        }

        case RECIPES_CATEGORIES_FETCH_SUCCESS: {
            return {
                ...state,
            }
        }

        case RECIPES_CATEGORIES_FETCH_FAIL: {
            return {
                ...state,
            }
        }

        case RECIPES_SUBCATEGORIES_FETCH_REQUEST: {
            return {
                ...state,
            }
        }

        case RECIPES_SUBCATEGORIES_FETCH_SUCCESS: {
            return {
                ...state,
            }
        }

        case RECIPES_SUBCATEGORIES_FETCH_FAIL: {
            return {
                ...state,
            }
        }

        case RECIPES_CATEGORY_SUBCATEGORIES_FETCH_REQUEST: {
            return {
                ...state,
            }
        }

        case RECIPES_CATEGORY_SUBCATEGORIES_FETCH_SUCCESS: {
            return {
                ...state,
            }
        }

        case RECIPES_CATEGORY_SUBCATEGORIES_FETCH_FAIL: {
            return {
                ...state,
            }
        }

        case RECIPE_INFOS_REQUEST : {
            return {
                ...state,
                loading: true
            }
        }


        case RECIPE_INFOS_SUCCESS : {
            return {
                ...state,
                recipeInfos:  action.payload,
                loading: false
            }
        }

        case RECIPE_INFOS_FAIL: {
            return {
                ...state,
                loading: false
            }
        }

        case RECIPE_CREATE_REQUEST : {
            return {
                ...state,
                recipeInfos: {},
                loading: true
            }
        }

        case RECIPE_CREATE_SUCCESS : {
            return {
                ...state,
                recipeInfos: action.payload,
                loading: false,
                error: null
            }
        }

        case RECIPE_CREATE_FAIL : {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }

        case USER_LOGOUT: {
            return {
                ...state,
                recipeInfos: {},
                loading: false,
                error: null
            }
        }

        default:
            return state
    }
}