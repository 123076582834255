import {
    apiCategories,
    apiCategory,
    apiLatestRecipes,
    apiRecipe,
    apiRecipes,
    apiSubCategories
} from "../constants/apiRoutes";
import axios from "axios";
import {headers} from "../constants/axiosConfig";

export const RECIPES_FETCH_REQUEST = 'RECIPES_FETCH_REQUEST';
export const RECIPES_FETCH_SUCCESS = 'RECIPES_FETCH_SUCCESS';
export const RECIPES_FETCH_FAIL = 'RECIPES_FETCH_FAIL';
export const RECIPES_CATEGORIES_FETCH_FAIL = 'RECIPES_CATEGORIES_FETCH_FAIL';
export const RECIPES_CATEGORIES_FETCH_REQUEST = 'RECIPES_CATEGORIES_FETCH_REQUEST';
export const RECIPES_CATEGORIES_FETCH_SUCCESS = 'RECIPES_CATEGORIES_FETCH_SUCCESS';
export const RECIPES_SUBCATEGORIES_FETCH_FAIL = 'RECIPES_SUBCATEGORIES_FETCH_FAIL';
export const RECIPES_SUBCATEGORIES_FETCH_REQUEST = 'RECIPES_SUBCATEGORIES_FETCH_REQUEST';
export const RECIPES_SUBCATEGORIES_FETCH_SUCCESS = 'RECIPES_SUBCATEGORIES_FETCH_SUCCESS';
export const RECIPES_CATEGORY_SUBCATEGORIES_FETCH_FAIL = 'RECIPES_CATEGORY_SUBCATEGORIES_FETCH_FAIL';
export const RECIPES_CATEGORY_SUBCATEGORIES_FETCH_REQUEST = 'RECIPES_CATEGORY_SUBCATEGORIES_FETCH_REQUEST';
export const RECIPES_CATEGORY_SUBCATEGORIES_FETCH_SUCCESS = 'RECIPES_CATEGORY_SUBCATEGORIES_FETCH_SUCCESS';
export const RECIPE_INFOS_FAIL = 'RECIPE_INFOS_FAIL';
 export const RECIPE_INFOS_REQUEST = 'RECIPE_INFOS_REQUEST';
 export const RECIPE_INFOS_SUCCESS = 'RECIPE_INFOS_SUCCESS';
 export const RECIPE_CREATE_REQUEST = 'RECIPE_CREATE_REQUEST';
 export const RECIPE_CREATE_FAIL = 'RECIPE_CREATE_FAIL';
 export const RECIPE_CREATE_SUCCESS = 'RECIPE_CREATE_SUCCESS';

export const fetchRecipesList = () => async (dispatch) => {
    try {
        dispatch({
            type: RECIPES_FETCH_REQUEST
        })
        const {data} = await axios.get(apiRecipes, headers())

        dispatch({
            type: RECIPES_FETCH_SUCCESS
        })

        return data;

    } catch(error) {
        dispatch({
            type: RECIPES_FETCH_FAIL,
            payload: error
        })
    }
}

export const fetchLatestRecipesList = () => async (dispatch) => {
    try {
        dispatch({
            type: RECIPES_FETCH_REQUEST
        })
        const {data} = await axios.get(apiLatestRecipes, headers())

        dispatch({
            type: RECIPES_FETCH_SUCCESS
        })

        return data;

    } catch(error) {
        dispatch({
            type: RECIPES_FETCH_FAIL,
            payload: error
        })
    }
}

export const fetchCategoriesList = () => async (dispatch) => {
    try {
        dispatch({
            type: RECIPES_CATEGORIES_FETCH_REQUEST
        })

        const {data} = await axios.get(apiCategories, headers())

        dispatch({
            type: RECIPES_CATEGORIES_FETCH_SUCCESS,
            payload: data
        })

        return data;

    } catch(error) {
        dispatch({
            type: RECIPES_CATEGORIES_FETCH_FAIL
        })
    }
}

export const fetchSubCategoriesList = () => async (dispatch) => {
    try {
        dispatch({
            type: RECIPES_SUBCATEGORIES_FETCH_REQUEST
        })
        const {data} = await axios.get(apiSubCategories, headers())
        dispatch({
            type: RECIPES_SUBCATEGORIES_FETCH_SUCCESS
        })
        return data;

    } catch(error) {
        dispatch({
            type: RECIPES_SUBCATEGORIES_FETCH_FAIL
        })
    }
}

export const fetchCategorySubcategories = (id) => async (dispatch) => {
    try {
        dispatch({
            type: RECIPES_CATEGORY_SUBCATEGORIES_FETCH_REQUEST
        })
        const {data} = await axios.get(`${apiCategory}/${id}/subcategories`, headers())
        dispatch({
            type: RECIPES_CATEGORY_SUBCATEGORIES_FETCH_SUCCESS
        })
        return data

    } catch(error) {
        dispatch({
            type: RECIPES_CATEGORY_SUBCATEGORIES_FETCH_FAIL
        })
    }
}

export const createRecipe = (title, instructions, userId, category, subcategory, aliments, recipeQuantities) => async (dispatch) => {
    try {
        dispatch({
            type: RECIPE_CREATE_REQUEST,
        })

        const data = await axios.post(apiRecipe, {
            title: title,
            instructions: instructions,
            user: userId,
            category: category,
            subcategory: subcategory,
            aliments: aliments,
            quantities: recipeQuantities
        }, headers())

        dispatch ({
            type: RECIPE_CREATE_SUCCESS,
            payload: data.config.data
        })
    } catch(error) {
        console.log('erreur : ', error)
        dispatch({
            type: RECIPE_CREATE_FAIL,
            payload: error
        })
    }
}
