// // Aliments
 export const apiAliment = process.env.REACT_APP_API_ALIMENT_ROUTE
 export const apiAliments = process.env.REACT_APP_API_ALIMENTS_ROUTE

//Recipes
export const apiRecipe = process.env.REACT_APP_API_RECIPE_ROUTE
export const apiRecipes = process.env.REACT_APP_API_RECIPES_ROUTE
export const apiLatestRecipes = process.env.REACT_APP_API_LATEST_RECIPES_ROUTE
export const apiCategory = process.env.REACT_APP_API_CATEGORY_ROUTE
export const apiCategories = process.env.REACT_APP_API_CATEGORIES_ROUTE
export const apiSubCategory = process.env.REACT_APP_API_SUBCATEGORY_ROUTE
export const apiSubCategories = process.env.REACT_APP_API_SUBCATEGORIES_ROUTE

//Registration
export const registrationRoute = process.env.REACT_APP_REGISTRATION_ROUTE

//Authentication
export const loginRoute = process.env.REACT_APP_API_LOGIN_ROUTE

//Profile
export const profileRoute = process.env.REACT_APP_API_PROFILE_ROUTE
export const userRoute = process.env.REACT_APP_API_USER_ROUTE

//fetch all events associated to a user
export const fetchEventsRoute = process.env.REACT_APP_API_FETCH_EVENTS_ROUTE

//fetch one single event
export const eventRoute = process.env.REACT_APP_API_FETCH_EVENT_ROUTE

//check if a username is available
export const verifyUsernameIsAvailableRoute = process.env.REACT_APP_API_VERIFY_USERNAME_IS_AVAILABLE