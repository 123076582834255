import React, {lazy, Suspense} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import PageContent from "./components/PageContent";
import {
    alimentsRoute, allRecipesRoute,
    diaryRoute, FAQRoute,
    homeRoute, notFoundRoute,
    profileRoute,
    recipesRoute,
    userRecipesRoute
} from "./constants/frontRoutes";
import { ThemeProvider } from "@mui/material";
import theme from "./shared-theme";
import {useDispatch} from "react-redux";
import NotFound from "./components/NotFound";
import Loader from "./components/Loader";
import UserRecipesList from "./components/UserRecipesList";
import RecipesList from "./components/RecipesList";
import RedirectHandler from "./utils/redirectHandler";
import FAQ from "./components/FAQ";

const DiaryScreen = lazy(() => import('./screens/DiaryScreen'))
const HomeScreen = lazy(() => import('./screens/HomeScreen'))
const ProfileScreen = lazy(() => import('./screens/ProfileScreen'))
const AlimentsScreen = lazy(() => import('./screens/AlimentsScreen'))
const RecipesScreen = lazy(() => import('./screens/RecipesScreen'))

const App = () => {
    const dispatch = useDispatch()
    return (
        <Router>
            <Suspense fallback={<Loader/>}>
                <ThemeProvider theme={theme}>
                    <Header/>
                    <RedirectHandler/>
                        <Routes>
                            <Route path={homeRoute} element={<HomeScreen />} />
                            <Route path={alimentsRoute} element={<AlimentsScreen/>} />
                            <Route path={profileRoute} element={<ProfileScreen/>} />
                            <Route path={recipesRoute} element={<RecipesScreen/>}>
                                <Route path={userRecipesRoute} element={<UserRecipesList/>}/>
                                <Route path={allRecipesRoute} element={<RecipesList/>}/>
                            </Route>
                            <Route path={diaryRoute} element={<DiaryScreen/>} />
                            <Route path={notFoundRoute} element={<PageContent><NotFound/></PageContent>}/>
                            <Route path={FAQRoute} element={<PageContent><FAQ/></PageContent>}/>
                        </Routes>
                    <Footer />
                </ThemeProvider>
            </Suspense>
        </Router>
    );
}

export default App;
