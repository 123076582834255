import {apiAliment, apiAliments} from "../constants/apiRoutes";
import axios from "axios";
import {headers} from "../constants/axiosConfig";

export const ALIMENTS_INFOS_REQUEST = 'ALIMENTS_INFOS_REQUEST';
export const ALIMENTS_INFOS_SUCCESS = 'ALIMENTS_INFOS_SUCCESS';
export const ALIMENTS_INFOS_FAIL = 'ALIMENTS_INFOS_FAIL';
export const ALIMENT_INFOS_REQUEST = 'ALIMENT_INFOS_REQUEST';
export const ALIMENT_INFOS_SUCCESS = 'ALIMENT_INFOS_SUCCESS';
export const ALIMENT_INFOS_FAIL = 'ALIMENT_INFOS_FAIL';

export const fetchAlimentsList = () => async (dispatch) => {
    dispatch({type: ALIMENTS_INFOS_REQUEST});

    try {
        const { data } = await axios.get(apiAliments, headers())

        dispatch({
            type: ALIMENTS_INFOS_SUCCESS,
            payload: data
        })
    } catch (error) {
        await dispatch({type: ALIMENTS_INFOS_FAIL, payload: error.message})
    }
}

export const fetchAlimentInfos = (id) => async (dispatch) => {
    dispatch({type: ALIMENT_INFOS_REQUEST});

    try {
        const { data } = await axios.get(`${apiAliment}/${id}`, headers())

        dispatch({
            type: ALIMENT_INFOS_SUCCESS,
            payload: data
        })

    } catch (error) {
        await dispatch({
            type: ALIMENT_INFOS_FAIL,
            payload: error.message
        })
    }
}
