import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Loader from "../Loader";
import {useOutletContext} from "react-router-dom";

const UserRecipesList = () => {

    const { fetchUserRecipes, userId } = useOutletContext();

    const [userRecipes, setUserRecipes] = useState([]);
    const loading = useSelector(state => state?.users?.loading)


    const fetchRecipe = (id) => {
        console.log(id)
    }

    useEffect(() => {
        fetchUserRecipes(userId)
            .then(data => setUserRecipes(data))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <>
            <section>
                <h2>Toutes mes recettes</h2>
                {loading &&
                <Loader></Loader>}
                { userRecipes &&
                userRecipes.map((recipe) =>
                    <ul key={recipe.id}>
                        <li>
                            <div onClick={() => fetchRecipe(recipe.id)}>Recette : {recipe.title}</div>
                            <p>Classée dans : {recipe.category} - {recipe.subcategory}</p>
                        </li>
                    </ul>
                ) }
            </section>
        </>
    )
}

export default UserRecipesList;