import {Link, useNavigate} from "react-router-dom";
import {alimentsRoute, diaryRoute, homeRoute, profileRoute, recipesRoute} from "../../constants/frontRoutes";
import {Button} from "@mui/material";
import {userLogout} from "../../actions/userActions";
import {useDispatch, useSelector} from "react-redux";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

function Navbar () {
    const isLoggedIn = useSelector(state=> state.users?.userInfos?.isLoggedIn)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogout = () => {
        dispatch(userLogout())
        navigate(homeRoute)
    }

    return (
        <>
        {isLoggedIn ?
            <nav className={"navbar"} aria-label={'main navigation'}>
                <Button className={"navbar__button"} title={'Se déconnecter'} aria-label={'Se déconnecter'}
                        onClick={handleLogout}><ExitToAppIcon></ExitToAppIcon></Button>
                <Link className={"navbar__link"} to={profileRoute} aria-label={'Profil'}>Profil</Link>
                <Link className={"navbar__link"} to={diaryRoute} aria-label={'Journal'}>Journal</Link>
                <Link className={"navbar__link"} to={alimentsRoute} aria-label={'Aliments'}>Aliments</Link>
                <Link className={"navbar__link"} to={recipesRoute} aria-label={'Recettes'}>Recettes</Link>
                <Link className={"navbar__link"} to={homeRoute} aria-label={'Accueil'}>Accueil</Link>
            </nav>
        :<div className={"banner"}></div>}
        </>
    );
}
    export default Navbar;
