import Lottie from "lottie-react";

export default function Logo(
    {
        animationData,
        loop
    }
) {
    return (
        <>
            <div className={"animated-logo"}>
                <Lottie animationData={animationData} loop={loop} />
            </div>
        </>
    );
};
