import Logo from "../Logo";
import page404 from "../../style/images/404-page.json";


export default function NotFound() {
    return (
        <div>
            <div className={"notfoundpage__animated-logo-container"}>
                <Logo
                    animationData={page404}
                    loop={true}
                >
                </Logo>
            </div>
            <h1>Oops&nbsp;! Cette page n'existe pas.</h1>
        </div>
    )
}