export const homeRoute = "/";
export const profileRoute = "/profil";
export const recipesRoute = "/recettes";
export const userRecipesRoute = "/recettes/mes-recettes";
export const allRecipesRoute = "/recettes/toutes-les-recettes";
export const alimentRoute = "/aliment";
export const alimentsRoute = "/aliments";
export const diaryRoute = "/journal";
export const FAQRoute = "/faq";
export const notFoundRoute = "*";

export const publicRoutes = [
    homeRoute,
    alimentRoute,
    alimentsRoute,
    alimentsRoute,
    diaryRoute,
    profileRoute
]