import axios from "axios";
import {headers} from "../constants/axiosConfig";
import {eventRoute} from "../constants/apiRoutes";

export const EVENT_FETCH_REQUEST = 'SINGLE_EVENT_FETCH_REQUEST';
export const EVENT_FETCH_SUCCESS = 'SINGLE_EVENT_FETCH_SUCCESS';
export const EVENT_FETCH_FAIL = 'SINGLE_EVENT_FETCH_FAIL';
export const EVENT_CREATE_REQUEST = 'EVENT_CREATE_REQUEST';
export const EVENT_CREATE_SUCCESS = 'EVENT_CREATE_SUCCESS';
export const EVENT_CREATE_FAIL = 'EVENT_CREATE_FAIL';
export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST';
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS';
export const EVENT_UPDATE_FAIL = 'EVENT_UPDATE_FAIL';
export const EVENT_DELETE_REQUEST = 'EVENT_DELETE_REQUEST';
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS';
export const EVENT_DELETE_FAIL = 'EVENT_DELETE_FAIL';
export const EVENT_RESET_REQUEST = 'EVENT_RESET_REQUEST';
export const EVENT_RESET_SUCCESS = 'EVENT_RESET_SUCCESS';
export const EVENT_RESET_FAIL = 'EVENT_RESET_FAIL';

export const fetchEvent = (eventId) => async (dispatch) => {
    try {
        dispatch({
            type: EVENT_FETCH_REQUEST
        })

        const {data} = await axios.get(`${eventRoute}/${eventId}`, headers())

        dispatch({
            type: EVENT_FETCH_SUCCESS,
            payload: data
        })

    } catch(error) {
        dispatch({
            type: EVENT_FETCH_FAIL,
            payload: error
        })
    }
}

export const fetchedEventReset = () => (dispatch) => {
    try {
        dispatch({
            type: EVENT_RESET_REQUEST
        })
        dispatch({
            type: EVENT_RESET_SUCCESS
        })
    } catch(error) {
        dispatch({
            type: EVENT_RESET_FAIL,
            payload: error
        })
    }
}

export const createNewEvent = (newEvent) => (dispatch) => {
    try {
        dispatch({
            type: EVENT_CREATE_REQUEST
        })

        axios.post(eventRoute, {
            title: newEvent.title,
            date: newEvent.date,
            user: newEvent.user,
            content: newEvent.content,
            totalPralIndex: newEvent.totalPralIndex
        }, headers()).then(r => console.log(r))

        dispatch({
            type: EVENT_CREATE_SUCCESS
        })

    } catch(error) {
        dispatch({
            type: EVENT_CREATE_FAIL,
            payload: error
        })
    }
}

export const updateEvent = (updatedEvent) => (dispatch) => {
    try {
        dispatch({
            type: EVENT_UPDATE_REQUEST
        })
        axios.post(`${eventRoute}/${updatedEvent.id}`, {
            title: updatedEvent.title,
            date: updatedEvent.date,
            user: updatedEvent.user,
            content: updatedEvent.content,
            totalPralIndex: updatedEvent.totalPralIndex
        }, headers()).then(r => console.log(r))

        dispatch({
            type: EVENT_UPDATE_SUCCESS
        })
    } catch(error) {
        dispatch({
            type: EVENT_UPDATE_FAIL
        })
    }
}

export const deleteEvent = (eventId) => async (dispatch) => {
    try {
        dispatch({
            type: EVENT_DELETE_REQUEST
        })
        const {response} = await axios.delete(
            `${eventRoute}/${eventId}`,headers()
        )

        dispatch({
            type: EVENT_DELETE_SUCCESS,
            payload: response
        })

    } catch(error) {
        console.log(error)
        dispatch({
            type: EVENT_DELETE_FAIL,
            payload: error
        })
    }
}
