export const headers = () => {
    return {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }
}

export const config = {
    headers: {
        "Content-Type": "application/json",
    },
}
